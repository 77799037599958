import React from "react"
import PropTypes from "prop-types"
import { Link, useLocation } from "react-router-dom"
import { pathsThatNotShowInCheckout } from "./Header"
import { useSelector } from "react-redux"

const Breadcrumb = ({ parent, sub, subChild, noBreadcrumb }) => {
  const location = useLocation()

  const { luckyDraw } = useSelector(state => ({
    luckyDraw: state.Products.luckyDraw,
  }))

  return (
    <>
      {!pathsThatNotShowInCheckout?.includes(location.pathname) && (
        <div className={`page-header breadcrumb-wrap ${noBreadcrumb}`}>
          <div className="container d-flex align-items-center justify-content-between">
            <div className="breadcrumb">
              <Link to="/">
                <span>{parent}</span>
              </Link>
              <span></span> {sub}
              <span></span> {subChild}
            </div>

            {luckyDraw?.isDrew === false && luckyDraw?.count === 0 ? (
              <>
                <div>
                  <span className="text-green">
                    We will announce the winner soon
                  </span>
                </div>
              </>
            ) : (
              <>
                {luckyDraw?.count >= 1 ? (
                  <div className="d-flex align-items-center ">
                    <span
                      className="lucky-draw-count pm"
                      style={{ fontSize: "16px" }}
                    >
                      {luckyDraw?.count}
                      {/* <FaArrowUpRightDots className="ms-2" /> */}
                    </span>
                    &nbsp; Products remain to be drawn
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default Breadcrumb

Breadcrumb.propTypes = {
  parent: PropTypes.any,
  sub: PropTypes.any,
  subChild: PropTypes.any,
  noBreadcrumb: PropTypes.any,
}

import {
  //cart
  ADD_OR_REMOVE,
  ADD_OR_REMOVE_FAIL,
  ADD_OR_REMOVE_SUCCESS,
  GET_USER_CART,
  GET_USER_CART_FAIL,
  GET_USER_CART_SUCCESS,
  GET_LOCAL_CART,
  GET_LOCAL_CART_SUCCESS,
  GET_LOCAL_CART_FAIL,
  BULK_CART,
  BULK_CART_ADDED,
  BULK_CART_FAIL,

  //coupon
  COUPON_VALIDATE,
  COUPON_VALIDATE_SUCCESS,
  COUPON_VALIDATE_FAIL,

  //order
  MAKE_ORDER,
  MAKE_ORDER_SUCCESS,
  MAKE_ORDER_FAIL,
  GET_USER_ORDERS,
  GET_USER_ORDERS_SUCCESS,
  GET_USER_ORDERS_FAIL,
  GET_ORDER_DETAILS,
  GET_ORDER_DETAILS_SUCCESS,
  GET_ORDER_DETAILS_FAIL,
  RE_ORDER,
  RE_ORDER_SUCCESS,
  RE_ORDER_FAIL,
  CANCEL_ORDER,
  CANCEL_ORDER_SUCCESS,
  CANCEL_ORDER_FAIL,
  RETURN_ORDER,
  RETURN_ORDER_SUCCESS,
  RETURN_ORDER_FAIL,
  UPDATE_ORDER,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAIL,
  CLEAR_ORDER_STATE,
  CREATE_EARNING_FROM_ORDER,
  CREATE_EARNING_FROM_ORDER_SUCCESS,
  CREATE_EARNING_FROM_ORDER_FAIL,

  //payment razorpay
  CREATE_RAZORPAY_ORDER,
  CREATE_RAZORPAY_ORDER_SUCCESS,
  CREATE_RAZORPAY_ORDER_FAIL,
  VERIFY_RAZORPAY_ORDER,
  VERIFY_RAZORPAY_ORDER_SUCCESS,
  VERIFY_RAZORPAY_ORDER_FAIL,

  //address
  GET_USER_ADDRESS,
  GET_USER_ADDRESS_SUCCESS,
  GET_USER_ADDRESS_FAIL,
  UPDATE_USER_ADDRESS,
  UPDATE_USER_ADDRESS_SUCCESS,
  UPDATE_USER_ADDRESS_FAIL,
  DELETE_USER_ADDRESS,
  DELETE_USER_ADDRESS_SUCCESS,
  DELETE_USER_ADDRESS_FAIL,
  SET_DEFAULT_ADDRESS,
  SET_DEFAULT_ADDRESS_SUCCESS,
  SET_DEFAULT_ADDRESS_FAIL,

  //favorite
  GET_FAVORITE_ITEMS,
  GET_FAVORITE_ITEMS_SUCCESS,
  GET_FAVORITE_ITEMS_FAIL,
  ADD_OR_REMOVE_FAV,
  ADD_OR_REMOVE_FAV_SUCCESS,
  ADD_OR_REMOVE_FAV_FAIL,

  //quick purchase
  GET_QUICKLIST_ITEMS,
  GET_QUICKLIST_ITEMS_SUCCESS,
  GET_QUICKLIST_ITEMS_FAIL,

  // payment
  GET_OPEN_PAYMENT_DETAILS_ORDER,
  GET_OPEN_PAYMENT_DETAILS_ORDER_SUCCESS,
  GET_OPEN_PAYMENT_DETAILS_ORDER_FAIL,

  // common
  CLEAR_ERROR,
  CLEAR_SERVER_CART,

  // PHONE PAY
  CREATE_PHONEPE_ORDER,
  CREATE_PHONEPE_ORDER_SUCCESS,
  CREATE_PHONEPE_ORDER_FAIL,
  VERIFY_PHONEPE_ORDER,
  VERIFY_PHONEPE_ORDER_SUCCESS,
  VERIFY_PHONEPE_ORDER_FAIL,
  CHAIN_PROMOTER_GENERATE,
  CHAIN_PROMOTER_GENERATE_SUCCESS,
  CHAIN_PROMOTER_GENERATE_FAIL,
  PAYMENT_CLEAR_ORDER_STATE,
  CREATE_IMA_PAYMENT,
  CREATE_IMA_PAYMENT_SUCCESS,
  CREATE_IMA_PAYMENT_FAIL,
  IMA_OTP_VERIFY,
  IMA_OTP_VERIFY_SUCCESS,
  IMA_OTP_VERIFY_FAIL,
  CLEAR_FULL_ORDER_STATE,
  COURSE_REGISTRATION,
  COURSE_REGISTRATION_SUCCESS,
  COURSE_REGISTRATION_FAIL,
  GET_COURSE_PROVIDER_DETAILS,
  GET_COURSE_PROVIDER_DETAILS_SUCCESS,
  GET_COURSE_PROVIDER_DETAILS_FAIL,
  CREATE_IDEAL_WALLET_PAYMENT,
  CREATE_IDEAL_WALLET_PAYMENT_SUCCESS,
  CREATE_IDEAL_WALLET_PAYMENT_FAIL,
} from "./actionTypes"

//cart
export const getUserCart = () => ({
  type: GET_USER_CART,
})
export const getUserCartSuccess = userCart => ({
  type: GET_USER_CART_SUCCESS,
  payload: userCart,
})
export const getUserCartFail = error => ({
  type: GET_USER_CART_FAIL,
  payload: error,
})

export const getLocalCart = () => ({
  type: GET_LOCAL_CART,
})
export const getLocalCartSuccess = userCart => ({
  type: GET_LOCAL_CART_SUCCESS,
  payload: userCart,
})
export const getLocalCartFail = error => ({
  type: GET_LOCAL_CART_FAIL,
  payload: error,
})

export const bulkCart = (cartItems, history) => ({
  type: BULK_CART,
  payload: { cartItems, history },
})
export const bulkCartAdded = userCart => ({
  type: BULK_CART_ADDED,
  payload: userCart,
})
export const bulkCartFail = error => ({
  type: BULK_CART_FAIL,
  payload: error,
})

export const addOrRemove = options => {
  return {
    type: ADD_OR_REMOVE,
    payload: options,
  }
}
export const addOrRemoveSuccess = cart => {
  return {
    type: ADD_OR_REMOVE_SUCCESS,
    payload: cart,
  }
}
export const addOrRemoveError = error => {
  return {
    type: ADD_OR_REMOVE_FAIL,
    payload: error,
  }
}

//coupon
export const couponValidate = coupon => ({
  type: COUPON_VALIDATE,
  payload: coupon,
})
export const couponValidateSuccess = couponvalue => ({
  type: COUPON_VALIDATE_SUCCESS,
  payload: couponvalue,
})
export const couponValidateFail = error => ({
  type: COUPON_VALIDATE_FAIL,
  payload: error,
})

//order
export const makeUserOrder = (input, history) => ({
  type: MAKE_ORDER,
  payload: { input, history },
})
export const makeUserOrderSuccess = order => ({
  type: MAKE_ORDER_SUCCESS,
  payload: order,
})
export const makeUserOrderFail = error => ({
  type: MAKE_ORDER_FAIL,
  payload: error,
})
export const updateUserOrder = (input, history) => ({
  type: UPDATE_ORDER,
  payload: { input, history },
})
export const updateUserOrderSuccess = order => ({
  type: UPDATE_ORDER_SUCCESS,
  payload: order,
})
export const updateUserOrderFail = error => ({
  type: UPDATE_ORDER_FAIL,
  payload: error,
})
export const getUserOrders = (page, limit) => ({
  type: GET_USER_ORDERS,
  payload: { page, limit },
})
export const getUserOrdersSuccess = userOrders => ({
  type: GET_USER_ORDERS_SUCCESS,
  payload: userOrders,
})
export const getUserOrdersFail = error => ({
  type: GET_USER_ORDERS_FAIL,
  payload: error,
})
export const getOrderDetails = orderId => ({
  type: GET_ORDER_DETAILS,
  payload: orderId,
})
export const getOrderDetailsSuccess = userOrders => ({
  type: GET_ORDER_DETAILS_SUCCESS,
  payload: userOrders,
})
export const getOrderDetailsFail = error => ({
  type: GET_ORDER_DETAILS_FAIL,
  payload: error,
})
export const reOrder = (orderId, history) => ({
  type: RE_ORDER,
  payload: { orderId, history },
})
export const reOrderSuccess = order => ({
  type: RE_ORDER_SUCCESS,
  payload: order,
})
export const reOrderFail = error => ({
  type: RE_ORDER_FAIL,
  payload: error,
})
export const cancelOrder = input => ({
  type: CANCEL_ORDER,
  payload: input,
})
export const cancelOrderSuccess = order => ({
  type: CANCEL_ORDER_SUCCESS,
  payload: order,
})
export const cancelOrderFail = error => ({
  type: CANCEL_ORDER_FAIL,
  payload: error,
})
export const returnOrder = input => ({
  type: RETURN_ORDER,
  payload: input,
})
export const returnOrderSuccess = order => ({
  type: RETURN_ORDER_SUCCESS,
  payload: order,
})
export const returnOrderFail = error => ({
  type: RETURN_ORDER_FAIL,
  payload: error,
})
export const clearOrderState = () => ({
  type: CLEAR_ORDER_STATE,
})
export const clearFullOrderState = () => ({
  type: CLEAR_FULL_ORDER_STATE,
})

export const paymentClearOrderState = () => ({
  type: PAYMENT_CLEAR_ORDER_STATE,
})

//payment
export const createRazorpayOrder = orderId => ({
  type: CREATE_RAZORPAY_ORDER,
  payload: { orderId },
})
export const createRazorpayOrderSuccess = orderDetails => ({
  type: CREATE_RAZORPAY_ORDER_SUCCESS,
  payload: orderDetails,
})
export const createRazorpayOrderFail = error => ({
  type: CREATE_RAZORPAY_ORDER_FAIL,
  payload: error,
})
export const verifyRazorpayOrder = razorpayRes => ({
  type: VERIFY_RAZORPAY_ORDER,
  payload: { razorpayRes },
})
export const verifyRazorpayOrderSuccess = orderDetails => ({
  type: VERIFY_RAZORPAY_ORDER_SUCCESS,
  payload: orderDetails,
})
export const verifyRazorpayOrderFail = error => ({
  type: VERIFY_RAZORPAY_ORDER_FAIL,
  payload: error,
})

//address
export const getUserAddress = () => ({
  type: GET_USER_ADDRESS,
})
export const getUserAddressSuccess = userAddress => ({
  type: GET_USER_ADDRESS_SUCCESS,
  payload: userAddress,
})
export const getUserAddressFail = error => ({
  type: GET_USER_ADDRESS_FAIL,
  payload: error,
})
export const UpdateUserAddress = (input, addressId) => ({
  type: UPDATE_USER_ADDRESS,
  payload: { input, addressId },
})
export const UpdateUserAddressSuccess = userAddress => ({
  type: UPDATE_USER_ADDRESS_SUCCESS,
  payload: userAddress,
})
export const UpdateUserAddressFail = error => ({
  type: UPDATE_USER_ADDRESS_FAIL,
  payload: error,
})
export const deleteUserAddress = addressId => ({
  type: DELETE_USER_ADDRESS,
  payload: addressId,
})
export const deleteUserAddressSuccess = userAddress => ({
  type: DELETE_USER_ADDRESS_SUCCESS,
  payload: userAddress,
})
export const deleteUserAddressFail = error => ({
  type: DELETE_USER_ADDRESS_FAIL,
  payload: error,
})
export const setDefualtAddress = (addressId, input) => ({
  type: SET_DEFAULT_ADDRESS,
  payload: { addressId, input },
})
export const setDefualtAddressSuccess = userAddress => ({
  type: SET_DEFAULT_ADDRESS_SUCCESS,
  payload: userAddress,
})
export const setDefualtAddressFail = error => ({
  type: SET_DEFAULT_ADDRESS_FAIL,
  payload: error,
})

//faviorite
export const getFavouriteItems = (page, limit) => ({
  type: GET_FAVORITE_ITEMS,
  payload: { page, limit },
})
export const getFavouriteItemsSuccess = favourite => ({
  type: GET_FAVORITE_ITEMS_SUCCESS,
  payload: favourite,
})
export const getFavouriteItemsFail = error => ({
  type: GET_FAVORITE_ITEMS_FAIL,
  payload: error,
})
export const addOrRemoveFav = productId => {
  return {
    type: ADD_OR_REMOVE_FAV,
    payload: productId,
  }
}
export const addOrRemoveFavSuccess = cart => {
  return {
    type: ADD_OR_REMOVE_FAV_SUCCESS,
    payload: cart,
  }
}
export const addOrRemoveFavError = error => {
  return {
    type: ADD_OR_REMOVE_FAV_FAIL,
    payload: error,
  }
}

//quiCk list
export const getQuicklist = (page, limit) => ({
  type: GET_QUICKLIST_ITEMS,
  payload: { page, limit },
})
export const getQuicklistSuccess = favourite => ({
  type: GET_QUICKLIST_ITEMS_SUCCESS,
  payload: favourite,
})
export const getQuicklistFail = error => ({
  type: GET_QUICKLIST_ITEMS_FAIL,
  payload: error,
})

//CREATE_EARNING_FROM_ORDER
export const createEarningFromOrder = earningsDetails => ({
  type: CREATE_EARNING_FROM_ORDER,
  payload: earningsDetails,
})
export const createEarningFromOrderSuccess = favourite => ({
  type: CREATE_EARNING_FROM_ORDER_SUCCESS,
  payload: favourite,
})
export const createEarningFromOrderFail = error => ({
  type: CREATE_EARNING_FROM_ORDER_FAIL,
  payload: error,
})

//CHAIN_PROMOTER_GENERATE
export const chainPromoterGenerate = orderId => ({
  type: CHAIN_PROMOTER_GENERATE,
  payload: orderId,
})
export const chainPromoterGenerateSuccess = date => ({
  type: CHAIN_PROMOTER_GENERATE_SUCCESS,
  payload: date,
})
export const chainPromoterGenerateFail = error => ({
  type: CHAIN_PROMOTER_GENERATE_FAIL,
  payload: error,
})

//OPEN PAYMENT
export const getOpenPaymentDetails = orderId => ({
  type: GET_OPEN_PAYMENT_DETAILS_ORDER,
  payload: orderId,
})
export const getOpenPaymentDetailsSuccess = orderDetails => ({
  type: GET_OPEN_PAYMENT_DETAILS_ORDER_SUCCESS,
  payload: orderDetails,
})
export const getOpenPaymentDetailsFail = error => ({
  type: GET_OPEN_PAYMENT_DETAILS_ORDER_FAIL,
  payload: error,
})

export const clearError = error => ({
  type: CLEAR_ERROR,
  payload: error,
})

export const clearServerCart = () => ({
  type: CLEAR_SERVER_CART,
})

//phone pay
export const createPhonePayOrder = orderId => ({
  type: CREATE_PHONEPE_ORDER,
  payload: { orderId },
})
export const createPhonePayOrderSuccess = orderDetails => ({
  type: CREATE_PHONEPE_ORDER_SUCCESS,
  payload: orderDetails,
})
export const createPhonePayOrderFail = error => ({
  type: CREATE_PHONEPE_ORDER_FAIL,
  payload: error,
})

export const verifyPhonePeOrder = transactionId => ({
  type: VERIFY_PHONEPE_ORDER,
  payload: { transactionId },
})
export const verifyPhonePeOrderSuccess = orderDetails => ({
  type: VERIFY_PHONEPE_ORDER_SUCCESS,
  payload: orderDetails,
})
export const verifyPhonePeOrderFail = error => ({
  type: VERIFY_PHONEPE_ORDER_FAIL,
  payload: error,
})

// IMA payment
export const createImaPayment = (orderId, phone) => ({
  type: CREATE_IMA_PAYMENT,
  payload: { orderId, phone },
})
export const createImaPaymentSuccess = paymentDetails => ({
  type: CREATE_IMA_PAYMENT_SUCCESS,
  payload: paymentDetails,
})
export const createImaPaymentFail = error => ({
  type: CREATE_IMA_PAYMENT_FAIL,
  payload: error,
})

// Idealsply payment
export const createIdealsplyWalletPayment = orderId => ({
  type: CREATE_IDEAL_WALLET_PAYMENT,
  payload: { orderId },
})
export const createIdealsplyWalletPaymentSuccess = paymentDetails => ({
  type: CREATE_IDEAL_WALLET_PAYMENT_SUCCESS,
  payload: paymentDetails,
})
export const createIdealsplyWalletPaymentFail = error => ({
  type: CREATE_IDEAL_WALLET_PAYMENT_FAIL,
  payload: error,
})

export const imaOptVerify = otp => ({
  type: IMA_OTP_VERIFY,
  payload: otp,
})
export const imaOptVerifySuccess = user => ({
  type: IMA_OTP_VERIFY_SUCCESS,
  payload: user,
})
export const imaOptVerifyFail = error => ({
  type: IMA_OTP_VERIFY_FAIL,
  payload: error,
})

//course
export const courseRegistration = (form, onCloseClick) => ({
  type: COURSE_REGISTRATION,
  payload: { form, onCloseClick },
})
export const courseRegistrationSuccess = res => ({
  type: COURSE_REGISTRATION_SUCCESS,
  payload: res,
})
export const courseRegistrationFail = error => ({
  type: COURSE_REGISTRATION_FAIL,
  payload: error,
})

export const getCourseProviderDetails = orderId => ({
  type: GET_COURSE_PROVIDER_DETAILS,
  payload: orderId,
})
export const getCourseProviderDetailsSuccess = res => ({
  type: GET_COURSE_PROVIDER_DETAILS_SUCCESS,
  payload: res,
})
export const getCourseProviderDetailsFail = error => ({
  type: GET_COURSE_PROVIDER_DETAILS_FAIL,
  payload: error,
})

import {
  GET_SITE_SETTINGS,
  GET_SITE_SETTINGS_SUCCESS,
  GET_SITE_SETTINGS_FAIL,
  GET_PAYMENT_KEY,
  GET_PAYMENT_KEY_SUCCESS,
  GET_PAYMENT_KEY_FAIL,
  OTHER_PAYMENT_OPTIONS,
  OTHER_PAYMENT_OPTIONS_SUCCESS,
  OTHER_PAYMENT_OPTIONS_FAIL,
} from "./actionTypes"

export const getSiteSettings = () => ({
  type: GET_SITE_SETTINGS,
})

export const getSiteSettingsSuccess = settings => ({
  type: GET_SITE_SETTINGS_SUCCESS,
  payload: settings,
})

export const getSiteSettingsFail = error => ({
  type: GET_SITE_SETTINGS_FAIL,
  payload: error,
})

export const getPaymentKey = () => ({
  type: GET_PAYMENT_KEY,
})

export const getPaymentKeySuccess = settings => ({
  type: GET_PAYMENT_KEY_SUCCESS,
  payload: settings,
})

export const getPaymentKeyFail = error => ({
  type: GET_PAYMENT_KEY_FAIL,
  payload: error,
})

export const getOtherPaymentOptions = () => ({
  type: OTHER_PAYMENT_OPTIONS,
})

export const getOtherPaymentOptionsSuccess = settings => ({
  type: OTHER_PAYMENT_OPTIONS_SUCCESS,
  payload: settings,
})

export const getOtherPaymentOptionsFail = error => ({
  type: OTHER_PAYMENT_OPTIONS_FAIL,
  payload: error,
})

import React from "react"
import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"
import { map } from "lodash"
import useCloudinary from "components/hooks/useCloudinaryImage"
import { useCustomDevice } from "components/hooks/useCustomDevice"
import useWindow from "components/hooks/useWindow"
import { useHistory } from "react-router-dom"
import LazyLoad from "react-lazyload"

function ImageGrid({ data }) {
  const gridSize = data?.gridSize
  const { isMobile } = useWindow()
  const history = useHistory()

  const web = (
    <div className="image-grid p-0">
      <Row>
        {map(data?.images, (img, key) => (
          <Col
            className="p-0"
            lg={12 / gridSize?.lg}
            xs={12 / gridSize?.sm}
            key={key}
            onClick={() => (data?.link ? history.push(`${data?.link}`) : "")}
            style={{ cursor: data?.link ? "pointer" : "default" }}
          >
            <div key="d" className="gridItem">
              <LazyLoad height={200}>
                <img
                  src={useCloudinary(
                    img?.url ?? "",
                    isMobile ? "w_500" : "w_700,q_70"
                  )}
                  height={"100%"}
                  width={"100%"}
                  alt="grid-images"
                />
              </LazyLoad>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  )

  const mobile = web

  return useCustomDevice(data?.platform, mobile, web)
}

export default ImageGrid

ImageGrid.propTypes = {
  data: PropTypes.any,
}

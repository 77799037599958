import React from "react"
import { Link, useHistory } from "react-router-dom"
import PropTypes from "prop-types"
import usePrice from "components/hooks/usePrice"
import useCloudinary from "components/hooks/useCloudinaryImage"
import LazyLoad from "react-lazyload"

function SearchListVertical({ product, setsHowList }) {
  const history = useHistory()
  const { currencyCode, oldPrice, price, currentVarient } = usePrice(product)

  const removeSpace = product?.primaryLang?.name?.replace(/ /g, "-")

  return (
    <>
      <div
        className=" mb-sm-5 mb-md-0 mt-3 wow animate__animated animate__fadeInUp"
        data-wow-delay="0"
        onClick={() => history.push(`/products/${removeSpace}`)}
      >
        <div className="product-list-small  animated animated">
          <article
            className="d-flex align-items-center hover-up"
            onClick={() => setsHowList(false)}
          >
            <figure className="mb-0 mx-3">
              <Link to={`/products/${removeSpace}`}>
                <LazyLoad height={20}>
                  <img
                    src={useCloudinary(
                      product.images[0]?.url,
                      "c_thumb,h_200,w_200"
                    )}
                    className="h:80px w:80px"
                    alt="Idealsply-product"
                    title="Idealsply-product"
                    loading="eager"
                  />
                </LazyLoad>
              </Link>
            </figure>
            <div className="col-md-8 mb-0">
              <p style={{ fontSize: "14px" }}>
                <Link className="text-black" to={`/products/${removeSpace}`}>
                  {product?.primaryLang?.name}
                  {currentVarient && <span>({currentVarient})</span>}
                </Link>
              </p>
              <div className="product-price">
                <span className="text-black">
                  {currencyCode} {price}{" "}
                </span>
                <span className="old-price ">{oldPrice}</span>
              </div>
            </div>
          </article>
        </div>
      </div>
    </>
  )
}

export default SearchListVertical
SearchListVertical.propTypes = {
  product: PropTypes.any,
  setsHowList: PropTypes.func,
}

import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_USER_CART,
  COUPON_VALIDATE,
  MAKE_ORDER,
  GET_USER_ORDERS,
  GET_ORDER_DETAILS,
  RETURN_ORDER,
  RE_ORDER,
  CANCEL_ORDER,
  ADD_OR_REMOVE,
  ADD_OR_REMOVE_FAV,
  CREATE_RAZORPAY_ORDER,
  DELETE_USER_ADDRESS,
  GET_FAVORITE_ITEMS,
  GET_QUICKLIST_ITEMS,
  GET_USER_ADDRESS,
  SET_DEFAULT_ADDRESS,
  UPDATE_USER_ADDRESS,
  VERIFY_RAZORPAY_ORDER,
  GET_LOCAL_CART,
  BULK_CART,
  UPDATE_ORDER,
  CREATE_EARNING_FROM_ORDER,
  GET_OPEN_PAYMENT_DETAILS_ORDER,
  CREATE_PHONEPE_ORDER,
  VERIFY_PHONEPE_ORDER,
  CHAIN_PROMOTER_GENERATE,
  CREATE_IMA_PAYMENT,
  IMA_OTP_VERIFY,
  COURSE_REGISTRATION,
  GET_COURSE_PROVIDER_DETAILS,
  CREATE_IDEAL_WALLET_PAYMENT,
} from "./actionTypes"

import {
  addOrRemoveError,
  addOrRemoveFavError,
  addOrRemoveFavSuccess,
  addOrRemoveSuccess,
  bulkCartAdded,
  bulkCartFail,
  cancelOrderFail,
  cancelOrderSuccess,
  chainPromoterGenerateFail,
  chainPromoterGenerateSuccess,
  couponValidateFail,
  couponValidateSuccess,
  courseRegistrationFail,
  courseRegistrationSuccess,
  createEarningFromOrderFail,
  createEarningFromOrderSuccess,
  createIdealsplyWalletPaymentFail,
  createIdealsplyWalletPaymentSuccess,
  createImaPaymentFail,
  createImaPaymentSuccess,
  createPhonePayOrderFail,
  createPhonePayOrderSuccess,
  createRazorpayOrderFail,
  createRazorpayOrderSuccess,
  deleteUserAddressFail,
  deleteUserAddressSuccess,
  getCourseProviderDetailsFail,
  getCourseProviderDetailsSuccess,
  getFavouriteItems,
  getFavouriteItemsFail,
  getFavouriteItemsSuccess,
  getLocalCartFail,
  getLocalCartSuccess,
  getOpenPaymentDetailsFail,
  getOpenPaymentDetailsSuccess,
  getOrderDetailsFail,
  getOrderDetailsSuccess,
  getQuicklistFail,
  getQuicklistSuccess,
  getUserAddressFail,
  getUserAddressSuccess,
  getUserCart,
  getUserCartFail,
  getUserCartSuccess,
  getUserOrdersFail,
  getUserOrdersSuccess,
  imaOptVerifyFail,
  imaOptVerifySuccess,
  makeUserOrderFail,
  makeUserOrderSuccess,
  reOrderFail,
  reOrderSuccess,
  returnOrderFail,
  returnOrderSuccess,
  setDefualtAddressFail,
  setDefualtAddressSuccess,
  UpdateUserAddressFail,
  UpdateUserAddressSuccess,
  updateUserOrderFail,
  updateUserOrderSuccess,
  verifyPhonePeOrderFail,
  verifyPhonePeOrderSuccess,
  verifyRazorpayOrderFail,
  verifyRazorpayOrderSuccess,
} from "./actions"

import { ApiPut, del, get, post, TOKEN_PREFIX } from "helpers/api_methods"
import { showToasterAction } from "store/actions"
import { showCartNotificationAction } from "store/actions"
import { clearLocalCart, useLocalCart } from "components/hooks/useCartAction"

//user cart
function getUserCartApi() {
  return get("/cart/user")
}
function cartAddOrRemoveApi(options) {
  return post("/cart/add-remove", options)
}

//coupon
function couponValidateApi(coupon) {
  return post("/coupon/user/validate", { couponCode: coupon })
}

//order
function bulkCartAddApi({ cartItems }) {
  return post("/cart/add/bulk", { cartItems: cartItems })
}
function makeNewOrderApi({ input }) {
  return post("/order/user/new", input)
}
function updateOrderApi({ input }) {
  return post("/order/user/update", input)
}

function fetchUserOrderApi({ page, limit }) {
  return get(`/order/user?page=${page ?? 1}&limit=${limit ?? 10}`)
}
function fetchOrderDetailApi(orderId) {
  return get(`/order/user/single/${orderId}`)
}
function cancelOrderApi(input) {
  return post(`/order/user/cancel/${input?.id}`, {
    cancelOrderItems: input?.cancelOrderItems,
  })
}
function returnOrderApi(input) {
  return post(`/order/user/return/${input?.id}`, {
    returnOrderItems: input?.returnOrderItems,
  })
}
function reOrderCartApi(orderId) {
  return post(`/cart/add/order/${orderId}`)
}

//payment
function createRazorpayOrderApi({ orderId }) {
  return get(`/payment/razorpay/order/${orderId}`)
}
function verifyRazorpayOrderApi(razorpayRes) {
  return post(`/payment/razorpay/verify`, razorpayRes)
}
//ideal wallet
function idealWalletPaymentApi({ orderId }) {
  return post(`/payment/idealsply-wallet/order/${orderId}`)
}
//ima wallet
function imaPaymentCreateApi({ orderId, phone }) {
  return post(`/payment/ima-wallet/order/${orderId}`, { phone: phone })
}
function imaOtpVerifyApi(otp) {
  console.log(otp)
  return post(`/payment/ima-wallet/verify`, otp)
}

// phone pay payment
function createPhonePeOrderApi({ orderId }) {
  return get(`/payment/phonepe/order/${orderId}`)
}
function verifyPhonePeOrderApi(transactionId) {
  return get(`/payment/phonepe/transaction/status/${transactionId}`)
}

//address
function getUserAddressApi() {
  return get("/user/address")
}
function updateUserAddressApi({ input, addressId }) {
  return ApiPut(`/user/address/${addressId}`, input)
}
function deleteUserAddressApi(addressId) {
  return del(`/user/address/${addressId}`)
}
function setDefualtAddressApi({ addressId, input }) {
  return post(`/user/address/default/${addressId}`, input)
}

//favourite
function getFavouriteItemApi({ page, limit }) {
  return get(`/favourite/user?page=${page ?? 1}&limit=${limit ?? 10}`)
}
function addOrRemoveFavouriteItemApi(productId) {
  return post(`/favourite/user/addOrRemove`, { product: productId })
}

//quick purchase
function getQuicklistItemApi({ page, limit }) {
  return get(`/quick-list-item/user?page=${page ?? 1}&limit=${limit ?? 10}`)
}

//open payment
function getOpenPaymentDetailsApi(orderID) {
  return get(`/payment/open/order/${orderID}`)
}

//course
function courseRegistrationApi({ form }) {
  return post(`/course-registration/user/new`, form)
}

function getCourseProviderDetailsApi(orderId) {
  return get(`/course-provider/user/order/${orderId}`)
}

/////
//user cart

function* fetchLocalCart() {
  try {
    const response = useLocalCart()
    if (response && response !== null) {
      yield put(getLocalCartSuccess(response))
    }
  } catch (error) {
    yield put(getLocalCartFail(error))
  }
}

function* fetchUserCart() {
  try {
    const response = yield call(getUserCartApi)
    yield put(getUserCartSuccess(response))
  } catch (error) {
    yield put(getUserCartFail(error))
  }
}

function* cartAddOrRemove({ payload }) {
  try {
    const response = yield call(cartAddOrRemoveApi, payload)
    if (response?._id) {
      yield put(
        addOrRemoveSuccess({ ...response, myVarientId: payload?.varient })
      )
      yield put(
        showToasterAction({
          data: { title: "Cart", message: "Cart Updated" },
          type: "success",
        })
      )
      yield put(showCartNotificationAction())
    } else if (response?.message === "Wrong authentication token") {
      yield put(
        showToasterAction({
          data: {
            title: "Info",
            message: "Wrong authentication token",
          },
          type: "warning",
        })
      )
      localStorage.removeItem(TOKEN_PREFIX)
    } else if (response?.message) {
      yield put(addOrRemoveError(response?.message))
      yield put(
        showToasterAction({
          data: {
            title: "Cart",
            message: response?.message ? response?.message : "try again",
          },
          type: "warning",
        })
      )
    }
  } catch (error) {
    yield put(addOrRemoveError(error))
    yield put(
      showToasterAction({
        data: { message: "please try again" },
        type: "warning",
      })
    )
  }
}
//coupon
function* couponValidate({ payload }) {
  try {
    const response = yield call(couponValidateApi, payload)
    if (typeof response === "number") {
      yield put(
        couponValidateSuccess({
          success: "Coupon " + payload + " Applied",
          response,
        })
      )
      yield put(
        showToasterAction({
          data: { message: "coupon Applied" },
          type: "success",
        })
      )
      yield put(getUserCart())
    } else {
      yield put(
        couponValidateSuccess({
          success: "Coupon " + payload + " Applied",
          response,
        })
      )
    }
  } catch (error) {
    if (error.response?.data?.message) {
      yield put(
        showToasterAction({
          data: { message: error.response?.data?.message },
          type: "error",
        })
      )
    }
    yield put(couponValidateFail(error.response?.data?.message))
  }
}

//order
function* makeNewOrder({ payload }) {
  try {
    const response = yield call(makeNewOrderApi, payload)
    if (response?.message) {
      yield put(makeUserOrderFail(response?.message))
    }
    if (response?.orderId) {
      yield put(makeUserOrderSuccess(response))
      if (payload.history) {
        payload.history.replace("/order-success")
      }
    }
  } catch (error) {
    yield put(makeUserOrderFail(error))
  }
}

//update order
function* updateOrder({ payload }) {
  try {
    const response = yield call(updateOrderApi, payload)
    if (response?.orderId) {
      yield put(updateUserOrderSuccess(response))
      payload.history.replace("/order-success")
      yield put(
        showToasterAction({
          data: {
            title: "Order",
            message: "Order Created Successfully",
          },
          type: "success",
        })
      )
    }
  } catch (error) {
    yield put(updateUserOrderFail(error))
  }
}

function* bulkCartAdd({ payload }) {
  try {
    const response = yield call(bulkCartAddApi, payload)
    yield put(bulkCartAdded(response))
    clearLocalCart()
    setTimeout(() => {
      payload.history.replace("/checkout")
    }, 2000)
  } catch (error) {
    yield put(bulkCartFail(error))
  }
}

function* fetchUserOrder({ payload }) {
  try {
    const response = yield call(fetchUserOrderApi, payload)
    yield put(getUserOrdersSuccess(response))
  } catch (error) {
    yield put(getUserOrdersFail(error))
  }
}
function* cancelOrder({ payload }) {
  try {
    const response = yield call(cancelOrderApi, payload)
    if (response) {
      yield put(cancelOrderSuccess(response))
      yield put(
        showToasterAction({
          data: {
            title: "Order",
            message: "Order Status Updated",
          },
          type: "success",
        })
      )
    }
  } catch (error) {
    yield put(cancelOrderFail(error))
  }
}
function* returnOrder({ payload }) {
  try {
    const response = yield call(returnOrderApi, payload)
    yield put(returnOrderSuccess(response))
  } catch (error) {
    yield put(returnOrderFail(error))
  }
}
function* fetchOrderDetail({ payload }) {
  try {
    const response = yield call(fetchOrderDetailApi, payload)
    yield put(getOrderDetailsSuccess(response))
  } catch (error) {
    yield put(getOrderDetailsFail(error))
  }
}
function* reOrderCart({ payload: { orderId, history } }) {
  try {
    const response = yield call(reOrderCartApi, orderId)
    yield put(reOrderSuccess(response))
    history.push("/checkout")
  } catch (error) {
    yield put(reOrderFail(error))
  }
}

//payment
function* createRazorpayOrder({ payload }) {
  try {
    const response = yield call(createRazorpayOrderApi, payload)
    yield put(createRazorpayOrderSuccess(response))
  } catch (error) {
    yield put(createRazorpayOrderFail(error))
  }
}
function* verifyingRazorpayOrder({ payload: { razorpayRes } }) {
  try {
    const response = yield call(verifyRazorpayOrderApi, razorpayRes)
    if (response) {
      yield put(
        showToasterAction({
          data: {
            title: "Payment",
            message: response?.message,
          },
          type: "success",
        })
      )
    }
    yield put(verifyRazorpayOrderSuccess(response))
  } catch (error) {
    yield put(verifyRazorpayOrderFail(error))
  }
}

//ideal wallet
function* idealWalletPaymentCreate({ payload }) {
  try {
    const response = yield call(idealWalletPaymentApi, payload)
    yield put(createIdealsplyWalletPaymentSuccess(response))
  } catch (error) {
    yield put(createIdealsplyWalletPaymentFail(error))
  }
}
//ima
function* imaPaymentCreate({ payload }) {
  try {
    const response = yield call(imaPaymentCreateApi, payload)
    yield put(createImaPaymentSuccess(response))
  } catch (error) {
    yield put(createImaPaymentFail(error))
  }
}
function* imaOtpVerify({ payload }) {
  try {
    const response = yield call(imaOtpVerifyApi, payload)
    yield put(imaOptVerifySuccess(response))
  } catch (error) {
    yield put(imaOptVerifyFail(error))
  }
}

// phone pay payment
function* createPhonePeOrder({ payload }) {
  try {
    const response = yield call(createPhonePeOrderApi, payload)
    yield put(createPhonePayOrderSuccess(response))
  } catch (error) {
    yield put(createPhonePayOrderFail(error))
  }
}
function* verifyingPhonePeOrder({ payload: { transactionId } }) {
  try {
    const response = yield call(verifyPhonePeOrderApi, transactionId)
    if (response) {
      yield put(
        showToasterAction({
          data: {
            title: "Payment",
            message: response?.message,
          },
          type: "success",
        })
      )
    }
    yield put(verifyPhonePeOrderSuccess(response))
  } catch (error) {
    yield put(verifyPhonePeOrderFail(error))
  }
}

//address
function* fetchUserAddress() {
  try {
    const response = yield call(getUserAddressApi)
    yield put(getUserAddressSuccess(response))
  } catch (error) {
    yield put(getUserAddressFail(error))
  }
}

function* updateUserAddress({ payload }) {
  try {
    const response = yield call(updateUserAddressApi, payload)
    yield put(UpdateUserAddressSuccess(response))
    yield put(
      showToasterAction({
        data: {
          title: "Shipping Address",
          message: "Shipping Address Updated Successfully",
        },
        type: "success",
      })
    )
  } catch (error) {
    yield put(UpdateUserAddressFail(error))
  }
}

function* setDefualtUserAddress({ payload }) {
  try {
    const response = yield call(setDefualtAddressApi, payload)
    yield put(setDefualtAddressSuccess(response))
    yield put(
      showToasterAction({
        data: {
          title: "Shipping Address",
          message: "Shipping Address Updated Successfully",
        },
        type: "success",
      })
    )
  } catch (error) {
    yield put(setDefualtAddressFail(error))
  }
}

//favourite

function* fetchFavouriteItem({ payload }) {
  try {
    const response = yield call(getFavouriteItemApi, payload)
    yield put(getFavouriteItemsSuccess(response))
  } catch (error) {
    yield put(getFavouriteItemsFail(error))
  }
}
function* addOrRemoveFavouriteItem({ payload }) {
  try {
    const response = yield call(addOrRemoveFavouriteItemApi, payload)
    yield put(addOrRemoveFavSuccess(response))
    yield put(getFavouriteItems(1, 10))
    yield put(
      showToasterAction({
        data: {
          title: "Wishlist",
          message: "Wishlist Updated",
        },
        type: "success",
      })
    )
  } catch (error) {
    yield put(addOrRemoveFavError(error))
  }
}

//quick purchase

function* fetchQuicklistItems({ payload }) {
  try {
    const response = yield call(getQuicklistItemApi, payload)
    yield put(getQuicklistSuccess(response))
  } catch (error) {
    yield put(getQuicklistFail(error))
  }
}

function* deleteUserAddress({ payload }) {
  try {
    const response = yield call(deleteUserAddressApi, payload)
    yield put(deleteUserAddressSuccess(response))
    yield put(
      showToasterAction({
        data: {
          title: "Shipping Address",
          message: "Shipping Address Updated Successfully",
        },
        type: "success",
      })
    )
  } catch (error) {
    yield put(deleteUserAddressFail(error))
  }
}

function createEarningFromOrderApi(earningsDetails) {
  return post("/earning/user/new", earningsDetails)
}
function* createEarningFromOrder({ payload }) {
  try {
    const response = yield call(createEarningFromOrderApi, payload)
    if (response?.message === "Promoter earning added successfully!") {
      localStorage?.removeItem("ref_id")
      yield put(createEarningFromOrderSuccess(response))
    }
  } catch (error) {
    yield put(createEarningFromOrderFail(error))
  }
}

function chainPromoterGenerateApi(orderId) {
  return get(`/chainpromoter/user/generate/order/${orderId}`)
}
function* chainPromoterGenerate({ payload }) {
  try {
    const response = yield call(chainPromoterGenerateApi, payload)
    if (response?.message === "Promoter earning added successfully!") {
      localStorage?.removeItem("ref_id")
      yield put(chainPromoterGenerateSuccess(response))
    }
  } catch (error) {
    yield put(chainPromoterGenerateFail(error))
  }
}

function* getOpenPaymentDetails({ payload }) {
  try {
    const response = yield call(getOpenPaymentDetailsApi, payload)
    yield put(getOpenPaymentDetailsSuccess(response))
  } catch (error) {
    yield put(getOpenPaymentDetailsFail(error))
  }
}

function* courseRegistration({ payload }) {
  try {
    const response = yield call(courseRegistrationApi, payload)
    yield put(courseRegistrationSuccess(response))
    if (payload.onCloseClick) {
      payload.onCloseClick()
    }
  } catch (error) {
    yield put(courseRegistrationFail(error))
  }
}

function* getCourseProviderDetails({ payload }) {
  try {
    const response = yield call(getCourseProviderDetailsApi, payload)
    if (response?.message) {
      yield put(getCourseProviderDetailsFail(response?.message))
    } else {
      yield put(getCourseProviderDetailsSuccess(response))
    }
  } catch (error) {
    yield put(getCourseProviderDetailsFail(error))
  }
}

function* cartSaga() {
  yield takeEvery(GET_USER_CART, fetchUserCart)
  yield takeEvery(GET_LOCAL_CART, fetchLocalCart)
  yield takeEvery(ADD_OR_REMOVE, cartAddOrRemove)
  yield takeEvery(GET_USER_ADDRESS, fetchUserAddress)
  yield takeEvery(COUPON_VALIDATE, couponValidate)
  yield takeEvery(MAKE_ORDER, makeNewOrder)
  yield takeEvery(UPDATE_ORDER, updateOrder)
  yield takeEvery(UPDATE_USER_ADDRESS, updateUserAddress)
  yield takeEvery(DELETE_USER_ADDRESS, deleteUserAddress)
  yield takeEvery(GET_USER_ORDERS, fetchUserOrder)
  yield takeEvery(GET_ORDER_DETAILS, fetchOrderDetail)
  yield takeEvery(SET_DEFAULT_ADDRESS, setDefualtUserAddress)
  yield takeEvery(CREATE_RAZORPAY_ORDER, createRazorpayOrder)
  yield takeEvery(VERIFY_RAZORPAY_ORDER, verifyingRazorpayOrder)
  yield takeEvery(RE_ORDER, reOrderCart)
  yield takeEvery(GET_FAVORITE_ITEMS, fetchFavouriteItem)
  yield takeEvery(ADD_OR_REMOVE_FAV, addOrRemoveFavouriteItem)
  yield takeEvery(GET_QUICKLIST_ITEMS, fetchQuicklistItems)
  yield takeEvery(CANCEL_ORDER, cancelOrder)
  yield takeEvery(RETURN_ORDER, returnOrder)
  yield takeEvery(BULK_CART, bulkCartAdd)
  yield takeEvery(CREATE_EARNING_FROM_ORDER, createEarningFromOrder)
  yield takeEvery(GET_OPEN_PAYMENT_DETAILS_ORDER, getOpenPaymentDetails)

  yield takeEvery(CREATE_PHONEPE_ORDER, createPhonePeOrder)
  yield takeEvery(VERIFY_PHONEPE_ORDER, verifyingPhonePeOrder)
  yield takeEvery(CHAIN_PROMOTER_GENERATE, chainPromoterGenerate)
  yield takeEvery(CREATE_IMA_PAYMENT, imaPaymentCreate)
  yield takeEvery(CREATE_IDEAL_WALLET_PAYMENT, idealWalletPaymentCreate)
  yield takeEvery(IMA_OTP_VERIFY, imaOtpVerify)
  yield takeEvery(COURSE_REGISTRATION, courseRegistration)
  yield takeEvery(GET_COURSE_PROVIDER_DETAILS, getCourseProviderDetails)
}

export default cartSaga
